
.tooltipArea{
  z-index:9998;
  height:auto;
  white-space:normal;
  color:#333;
  overflow:visible;
  position:fixed;
  font-size:0.73rem;
  font-weight:normal;

  u.arrow{
    width:1rem;
    height:.5rem;
    margin:0.3rem;
    position:absolute;
    display:block;
    left:calc(50% - 0.5rem);
    bottom:calc(-.5rem + -5px);
    @include border-radius($dropdown-border-radius);
    // bottom:-10px;
    z-index:9999;
    &::before, &::after{
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
    }

    &::after{
      bottom: 1px;
      border-width: .5rem .5rem 0;
      border-top-color: #fff !important;
    }
    &::before{
      bottom: 0;
      border-width: .5rem .5rem 0;
      border-top-color: rgba(0,0,0,.25) !important;;
    }
  }
}

.tooltipArea2{
  span{
    display:block;
    font-size:0.73rem !important;
    color:#333;
    // background:#000;
    text-align:left !important;
    font-weight:normal;
    // min-width:230px;
    //white-space:nowrap;
    .viewBtn{
      padding-top:0.22rem;
      padding-bottom:0.22rem;
      font-size:0.69rem !important;
      margin-top:6px;
      margin-bottom:3px;
    }
  }
  .tooltip-inner{
    background:#fff;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
    border-radius: .3rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    max-width:100%;
  }
  .tooltip{
    &.show {
      opacity: 1 !important;
    }
  }

  &.must-top{
    .arrow{
      &::before{
        margin-top:0;
      }
      &::after{
        margin-top:1px;
      }
    }
  }

  .arrow{
    width:12px;
    height:12px;
    display:block;
    bottom:2px;
    &::before{
      display:block;
      width:12px;
      height:12px;
      @include border-radius($dropdown-border-radius);
      background-color:$dropdown-border-color;
      transform:rotate(45deg);
      -webkit-transform:rotate(45deg);   
      content:'';
      border:0 !important;
    }
    &::after{
      display:block;
      width:12px;
      height:12px;
      background-color: $dropdown-bg;
      transform:rotate(45deg);
      -webkit-transform:rotate(45deg);
      @include border-radius($dropdown-border-radius);
      content:'';
      position:relative;
      margin-top:-1px;
    }
  }
}


.rc-slider-tooltip {
  z-index: 555;
}

.rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}

.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  animation-play-state: paused;
}

.rc-tooltip-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  animation-play-state: paused;
}

.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  animation-name: rcToolTipZoomIn;
  animation-play-state: running;
}

.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  animation-name: rcToolTipZoomOut;
  animation-play-state: running;
}

@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}

@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
}

.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
  opacity: 0.9;
}

.rc-tooltip-hidden {
  display: none;
}

.rc-tooltip-placement-top,
.rc-tooltip-placement-topLeft,
.rc-tooltip-placement-topRight {
  padding: 5px 0 9px 0;
}

.rc-tooltip-placement-right,
.rc-tooltip-placement-rightTop,
.rc-tooltip-placement-rightBottom {
  padding: 0 5px 0 9px;
}

.rc-tooltip-placement-bottom,
.rc-tooltip-placement-bottomLeft,
.rc-tooltip-placement-bottomRight {
  padding: 9px 0 5px 0;
}

.rc-tooltip-placement-left,
.rc-tooltip-placement-leftTop,
.rc-tooltip-placement-leftBottom {
  padding: 0 9px 0 5px;
}

.rc-tooltip-inner {
  padding: 12px 14px;
  color: $white;
  text-align: left;
  text-decoration: none;
  background-color: $gray-800;
  border-radius: 6px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  min-height: 34px;
  font-weight: bold;
}

.rc-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  bottom: 4px;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: $gray-800;
}

.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}

.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}

.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}

.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  left: 4px;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: $gray-800;
}

.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}

.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}

.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}

.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  right: 4px;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: $gray-800;
}

.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}

.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}

.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  top: 4px;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: $gray-800;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}

.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}

.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}
