// User Pages

.app-logo {
  height: $logo-height;
  width: $logo-width;
  background: url('~assets/utils/images/logo-inverse.png');
}

.app-logo-inverse {
  // height: $logo-height;
  // width: $logo-width;
  height: 21px;
  width: 160px;
  background: url('~assets/utils/images/member/logo.png');
}

.app-login-box {
  // padding:3rem 0;
  margin-top:2rem;
  padding-bottom:2rem;
  .app-logo {
    margin-bottom: $layout-spacer-lg;
  }

  h4 {
    // margin-bottom: $layout-spacer-x;
    font-weight: normal;

    div {
      opacity: .6;
    }

    span {
      font-size: 0.85rem;
    }
  }

  h6{
    span{
      line-height:1.4;
    }
  }
}


.app-login-box{
  .modal-title{
    margin:0.5rem 0 1.5rem;
  }
}

.app-login-box{
  .modal-header{
    .modal-title{
      margin:0.3rem 0 0.1rem;
    }
  }
  
}

.boxCopyright{
  font-size:0.8rem;
}

.iframeStyle{
  height: 80vh;
  padding: 10px 0 10px 10px;
  background-color:#FFFFFF;
  margin-top:10px;
}

