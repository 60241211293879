
#zz10_0301_cont__grid .ag-cell-data-changed {
    background-color: transparent !important;
}


#zz10_0301_cont__grid .ag-cell-data-changed-animation {
    background-color: transparent;
    transition: background-color 0s;
}
