@if index($hamburger-types, arrowturn-r) {
  /*
   * Arrow Turn Right
   */
  .hamburger--arrowturn-r.is-active {
    .hamburger-inner {
      transform: rotate(-180deg);

      &::before {
        //transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
        transform: translate3d(-9px, 0, 0) rotate(-45deg) scale(0.5, 1);
        top:-4px;
      }

      &::after {
        //transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
        transform: translate3d(-9px, 0, 0) rotate(45deg) scale(0.5, 1);
        bottom:-4px;
      }
    }
  }
}
