%triangle-arrow {
  margin-left: -$datepicker__triangle-size;
  position: absolute;

  &,
  &::before {
    box-sizing: content-box;
    position: absolute;
    border: $datepicker__triangle-size solid transparent;

    height: 0;
    width: 1px;
  }

  &::before {
    content: "";
    z-index: -1;
    border-width: $datepicker__triangle-size;

    left: -$datepicker__triangle-size;
    border-bottom-color: $datepicker__border-color;
  }
}

%triangle-arrow-up {
  @extend %triangle-arrow;

  top: 0;
  margin-top: -$datepicker__triangle-size;

  &,
  &::before {
    border-top: none;
    border-bottom-color: $datepicker__background-color;
  }

  &::before {
    top: -1px;
    border-bottom-color: $datepicker__border-color;
  }
}

%triangle-arrow-down {
  @extend %triangle-arrow;

  bottom: 0;
  margin-bottom: -$datepicker__triangle-size;

  &,
  &::before {
    border-bottom: none;
    border-top-color: #fff;
  }

  &::before {
    bottom: -1px;
    border-top-color: $datepicker__border-color;
  }
}

.triangle {
  vertical-align:middle;
  margin-left:0.2rem;
  &::before, &::after{
    content:'';
    display:inline-block; 
    width:0; 
    height:0; 
    border-style:solid; 
    border-width:6px;
    display:none;  
  }

  &.w5{
    &::before, &::after{
      border-width:5px;
    }
  }

  &.align-middle{
    &::before{
      vertical-align:middle;
      top:2px;
      //margin-right:3px;
    }
    &::after{
      vertical-align:middle;
      top:-3px;
    }
  }

  &::before{
    border-color:#495057 transparent transparent transparent;
     
  }
  &::after{
    border-color: transparent transparent transparent #495057 ;
    position:relative;
    top:1px;
    vertical-align:top;
  }
}

.tri-expanded[aria-expanded=true]{
  .triangle{
    &::before{
      display:inline-block;
    }
    &::after{
      display:none;
    }
  }
}

.tri-expanded[aria-expanded=false]{
  .triangle{
    &::before{
      display:none;
    }
    &::after{
      display:inline-block;
    }
  }
}


.faAngle{
  .Angle{
    position:absolute;
    &.mini{
      position:relative;
      float:left;
      margin-right:0.5rem;
      top:2px;
      color:#545cd8;
      svg{
        font-size:1.3rem !important;
      }
      &.middle{
        svg{
          font-size:1.4rem !important;
        }
      }
    }
  }
}

.faAngle[aria-expanded=true]{
  .Angle{
    .up{
      display:none;
    }
    .down{
      display:block;
    }
  }
}

.faAngle[aria-expanded=false]{
  .Angle{
    .up{
      display:block;
    }
    .down{
      display:none;
    }
  }
}




