// stylelint-disable selector-no-qualifying-type

//
// Textual form controls
//

.form-control {
  display: block;
  width: 100%;
  //height: $input-height;
  height: 39px;
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size-sm);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @include border-radius($input-border-radius, 0);

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus();

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }

  &.inline-block {
    display: inline-block;
    width: inherit;

    &.w250 {
      width: 250px;
    }
  }

  &.inline-block-w {
    display: inline-block;
    width: auto;
  }
}

select.form-control {
  &:focus::-ms-value {
    // Suppress the nested default white text on blue background highlight given to
    // the selected option text when the (still closed) <select> receives focus
    // in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
    // match the appearance of the native widget.
    // See https://github.com/twbs/bootstrap/issues/19398.
    color: $input-color;
    background-color: $input-bg;
  }
}

// Make file inputs better match text inputs by forcing them to new lines.
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

//
// Labels
//

// For use with horizontal and inline forms, when you need the label (or legend)
// text to align with the form controls.
.col-form-label {
  padding-top: calc(#{$input-padding-y} + #{$input-border-width});
  padding-bottom: calc(#{$input-padding-y} + #{$input-border-width});
  margin-bottom: 0; // Override the `<label>/<legend>` default
  @include font-size(inherit); // Override the `<legend>` default
  line-height: $input-line-height;
}

.col-form-label-lg {
  padding-top: calc(#{$input-padding-y-lg} + #{$input-border-width});
  padding-bottom: calc(#{$input-padding-y-lg} + #{$input-border-width});
  @include font-size($input-font-size-lg);
  line-height: $input-line-height-lg;
}

.col-form-label-sm {
  padding-top: calc(#{$input-padding-y-sm} + #{$input-border-width});
  padding-bottom: calc(#{$input-padding-y-sm} + #{$input-border-width});
  @include font-size($input-font-size-sm);
  line-height: $input-line-height-sm;
}

// Readonly controls as plain text
//
// Apply class to a readonly input to make it appear like regular plain
// text (without any border, background color, focus indicator)

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: $input-padding-y;
  padding-bottom: $input-padding-y;
  margin-bottom: 0; // match inputs if this class comes on inputs with default margins
  line-height: $input-line-height;
  color: $input-plaintext-color;
  background-color: transparent;
  border: solid transparent;
  border-width: $input-border-width 0;

  &.form-control-sm,
  &.form-control-lg {
    padding-right: 0;
    padding-left: 0;
  }
}

// Form control sizing
//
// Build on `.form-control` with modifier classes to decrease or increase the
// height and font-size of form controls.
//
// Repeated in `_input_group.scss` to avoid Sass extend issues.

.form-control-sm {
  height: $input-height-sm;
  padding: $input-padding-y-sm $input-padding-x-sm;
  @include font-size($input-font-size-sm);
  line-height: $input-line-height-sm;
  @include border-radius($input-border-radius-sm);
}

.form-control-lg {
  height: $input-height-lg;
  padding: $input-padding-y-lg $input-padding-x-lg;
  @include font-size($input-font-size-lg);
  line-height: $input-line-height-lg;
  @include border-radius($input-border-radius-lg);
}

// stylelint-disable-next-line no-duplicate-selectors
select.form-control {
  &[size],
  &[multiple] {
    height: auto;
  }
}

textarea.form-control {
  height: auto;
  resize: none;

  &.md {
    height: 160px;
  }
  &.md2 {
    height: 156px;
  }
}

.inline-block {
  display: inline-block;
  vertical-align: middle;
}

.vertical-middle {
  vertical-align: middle;
}

.form-control{
  &.w110{
    width:110px;
  }
}

.table {
  display: table;
}
.table-cell {
  display: table-cell;
  vertical-align: middle;
  padding: 0;

  &.w40p {
    width: 40%;
  }

  &.w140 {
    width: 140px;
  }

  &.w156 {
    width: 156px;
  }

  &.w180 {
    width: 180px;
  }

  &.w250 {
    width: 250px;
  }

  &.sm {
    width: 50px;
  }

  &.md {
    width: 136px;
  }

  &.md50 {
    width: 50px;
  }

  &.label {
    width: 120px;
    vertical-align: top;
    padding-top: 8px;
    margin-bottom: 0;
    font-weight: 500;
    color: #333;

    &.w90 {
      width: 90px;
    }

    &.w100 {
      width: 100px;
    }

    &.w140 {
      width: 140px;
    }

    &.w156 {
      width: 156px;
    }
  

    &.w170 {
      width: 170px;
    }

    &.w200 {
      width: 200px;
    }

    .badge {
      margin-left: 4px;
    }

    label {
      vertical-align: middle;
    }
  }

  &.bt {
    .btn {
      width: 100%;
      //font-size: 0.8rem;
    }
  }

  &.vertical-top {
    vertical-align: top;
  }
}

.table-max1024 {
  display: table;
  width: 100%;
}

.list-style {
  ul {
    margin: 0;
    padding-left: 0.5rem;
    li {
      list-style: none;
      position: relative;
      padding-left: 7px;
      margin-bottom: 0.4rem;

      &:before {
        content: '';
        position: absolute;
        top: 9px;
        left: 0;
        width: 3px;
        height: 3px;
        border-radius: 2px;
        background: #6c757d;
      }
    }
  }
}

.bt-tx-warp {
  .btn {
    width: 140px;

    i {
      padding-right: 0.4rem;
      &.lg {
        font-size: 1.04rem;
        vertical-align: -1px;
      }
    }
  }

  .inline-block {
    // display: block;
    margin-top: 0.65rem;
  }

  // &:last-child {
  //   .inline-block {
  //     margin-bottom: 0;
  //   }
  // }
}

.manual-wrap {
  text-align: center;
  display: table;
  width: 100%;

  .manual-con {display: table-cell; vertical-align: middle;}

  h4 {font-size: 1.3rem; color: #595b83; line-height: 1.5;}
}

.fontIcon {
  display: inline-block;
  .down {transform: rotate(180deg);}
}

.state-wrap {
  .alert {padding: 0.3rem 1rem; font-size: 0.8rem;}
}

// Form groups
//
// Designed to help with the organization and spacing of vertical forms. For
// horizontal forms, use the predefined grid classes.

// .filter-content{
//   width:700px !important;
//   // max-width:700px !important
// }

.filter-content{
  .drawer-section{
    padding-bottom:90px !important;
  }
}

.filter-warp {
  position: relative;
  padding-bottom: 75px;
  word-break: break-all;
  font-size: 0.8rem !important;
  .table-cell {
    &.label {
      // text-align: right;
      padding-right: 13px;
      position: relative;
      // width: 190px;
      label {
        padding-left: 23px;
      }
    }
    padding-right: 28px;

    &.clone {
      padding-right: 0;
      .Con {
        position: relative;
        padding-right: 28px;
        margin-bottom: 0.7rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  li {
    position: relative;
    //padding-right:30px;
  }

  .add-comment {
    position: relative;
  }

  .date-area{
    display: flex;
    align-items: center;
    .sign {
      width: 5%;
      text-align: center;
    }
    .start,
    .end {
      width: 47.5%;
    }

  }

  .min-icon {
    width: 18px;
    height: 18px;
    border: 1px solid #b9bec3;
    color: #b9bec3;
    line-height: 16px;
    text-align: center;
    font-weight: bold;
    border-radius: 50%;
    vertical-align: middle;
    position: absolute;
    top: 10px;
    cursor: pointer;
    z-index: 500;
    transition: color 0.15s, background-color 0.15s, border-color 0.15s,
      box-shadow 0.15s;
    -webkit-transition: color 0.15s, background-color 0.15s, border-color 0.15s,
      box-shadow 0.15s;
    &.remove-icon:after {
      content: '-';
    }
    &.add-icon:after {
      content: '+';
    }
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
    &:hover {
      background: #545cd8;
      color: #fff;
      border-color: #fff;
    }
  }
}
.en .filter-warp .table-cell {
  &.label {
    width: 200px;
  }
}

@media (max-width: 768px) {
  .filter-content {
    width:100% !important;
    .filter-warp {
      .table-cell {
        &.label {
          text-align:left;
          width:100%;
          label{
            //padding-left:0;
          }
        }
      }
      .min-icon{
        top:0;
        &.right{
          top:10px;
        }
      }
    }
  }  

  .parentDrawerArea{
    width:100% !important;
    max-width:100% !important;
  }
}


@media (max-width: 480px) {
  .filter-warp{
    .date-area{
        display:block;
        .start, .end{
          width:100%;
        }
    }
  }
}

.footer-btn {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  z-index: 500;
}

.react-datepicker-popper[data-placement^='bottom-end']
  .react-datepicker
  .react-datepicker__triangle {
  left: inherit;
  right: 50px !important;
}

.form-group {
  margin-bottom: $form-group-margin-bottom;

  &.inline {
    li {
      display: table;
      width: 100%;
      list-style: none;
      margin-bottom: 0.7rem;
      color: #6c757d;

      &:last-child {
        margin-bottom: 0;
      }

      &.has-mb {
        margin-bottom: 0.7rem;
      }

      &.fixed {
        table-layout: fixed;
      }

      .fs {
        font-size: 0.82rem;
      }

      .mobile-auth {
        .form-control {
          width: 210px;

          &.auth-num {
            width: 150px;
          }
        }
        .auth-time {
          display: inline-block;
          width: 60px;
        }
        .btn {
          width: 104px;

          .auth-state {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }

      .ic-qs {
        vertical-align: 1px;
      }

      .table-cell {
        .ic-qs {
          vertical-align: 3px;
        }
      }

      &.display-none {
        display: none;
      }
    }
    label {
      // display: table-cell;
      // width: 120px;
      // vertical-align: top;
      // padding-top: 8px;
      // margin-bottom: 0;
      // padding-right: 18px;

      .badge {
        margin-left: 5px;
        // vertical-align: -3px;
      }
    }

    .btn {
      //line-height: 1.5;
    }
  }
}

.text-wrap {
  padding: 0.9rem 0 1.4rem;
  h4 {font-size: 1.2rem; color: #595b83; font-weight: 500; line-height: 1.5;}
  p {font-size: 0.94rem; margin-bottom: 0.4rem; color: #6c757d;}
}

.tutorial-pop {
  .page-title-heading {
    margin: 0 auto!important;
  }
}

.tutorial-wrap {
  width: 100%;
  height: 100%;
  background: #dfdfdf;


  .title {
    position: absolute;
    top: 40%;
    left: 31%;
    text-align:center;
    color:#fff;
    font-size: 3.2rem;
  }
  
  .go-pop2 {
    position: absolute;
    top: 60%;
    left: 45%;
    font-size: 25px;
    border: none;
  }
  .slick-dots {
    padding: 15px 0;
    margin: 0;
    background: #000;
  }
  .slick-dots li {
    width: 20%;
    margin: 0;
    padding: 0;
  }

  .slick-dots a {
    color: #fff;
    &:hover {
      color: #878df7;
    }
    &:focus {
      color: #878df7;
    }
  }

  .slick-arrow {
    background: none;
    top: 98%;
    color: #fff;

    &:hover, &:focus {
      background: none;
      color: #878df7;
    }
  }
  .slick-slide img {
    width: 100%;
    height: 100%;
  }  
}

.channel-form {
  .connection-list-wrap {
    //overflow: hidden;    
    &:not(:last-child) {/*border-bottom: 1px solid #e6e9ec; padding-bottom: 1.5rem;*/}
    margin-bottom: 25px;

    .connection-list {     
      margin-bottom: 15px;

      .connection-container {
        padding-top: 1.6rem;
        background: #fff;
        box-shadow: $box-shadow-default;
        border-bottom: 4px solid transparent;
        @include border-radius($card-border-radius);

        &:nth-child(5n) {margin-right: 0;}

        .status {
          height: 23px;
          text-align: right;
          .badge {border-top-right-radius: 0; border-bottom-right-radius: 0; padding: 6px 10px 6px 17px;}
        }

        .contents {
          padding: 0.5rem 1.4rem 1rem;
          h3 {font-weight: 700; color: #495057; 
            &.hotelName{font-size: 1.5rem; margin-bottom: 1.5rem; width: 100%; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; word-wrap:normal;}
          }
          p {font-size: 0.84rem; color: #92969a; margin-bottom: 0;}
        }

        .footer {
          position: relative;
          padding: 0.8rem 1.2rem 0.3rem 1.2rem;
          border-top: 1px solid #e3e4f1;
          .state {
            font-size: 0.95rem;
            font-weight: 500;
            color: #495057;
            padding: 0;

            .round {width: 25px; height: 25px; border-radius: 13px; display: inline-block; vertical-align: -8px;}
          }

          .btn-group{
            // position: absolute; top: calc(50% - 10px); right: 10px;
            position: absolute; top: calc(50% - 20px); right: 0px;
            // top:6px;
            .btn-link {font-size: 1.1rem; color: #b5b6cb;}
          }
        }
      }
    }
  }
}

@media (min-width: 471px) {
  .channel-form {
    .connection-list-wrap {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px 20px;
      .connection-list {
        padding: 0 15px;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
}

@media (min-width: 769px) {
  .channel-form {
    .connection-list-wrap {
      .connection-list {
        -ms-flex: 0 0 33.33%;
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }
    }
  }
}

@media (min-width: 1025px) {
  .table-max1024 {
    width: 50%;
    float: left;

    &:nth-child(2n) {
      .label {
        text-align: right;
        padding-right: 1.5rem;
      }
    }
  }

  .table-cell {
    &.lang {
      width: 40%;
    }
  }

  .pc-pr {
    padding-right: 0.7rem;
  }

  .channel-form {
    .connection-list-wrap {
      .connection-list {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }
}

@media (min-width: 1401px) {
  .channel-form {
    .connection-list-wrap {
      .connection-list {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
  }
}

@media (max-width: 1024px) {
  .block-max1024 {
    display: block;
    width: 100%;
  }
  .form-group {
    &.inline {
      li {
        margin-bottom: 0.6rem;
      }
    }
  }
  .table-cell {
    &.pc-pr {
      margin-bottom: 0.6rem;
    }
  }
  .table-max1024 {
    margin-bottom: 0.6rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

}

@media (max-width: 768px) {
  .table-cell {
    display: block;
    width: 100%;
    margin-bottom: 0.5rem;

    &.label {
      padding-top: 0;
    }

    &.md {
      width: 49%;
      margin-right: 2%;
      float: left;
      padding-left: 0 !important;

      &:last-child {
        margin-right: 0;
      }
    }
  }

}

@media (min-width: 641px) {
}

@media (max-width: 640px) {
  .table-cell {
    &.mb-block {
      display: block;
      width: 100%;
    }
  }
  .form-group {
    margin-bottom: $form-group-margin-bottom;

    &.inline {
      li {
        .mobile-auth {
          .form-control {
            width: 130px;

            &.auth-num {
              width: 104px;
            }
          }
          .auth-time {
            width: 40px;
          }
          .mb-mt {
            margin-top: 0.4rem;
          }
        }
      }
    }
  }
}

.form-group.last {
  margin-bottom: 0;
}

.form-text {
  display: block;
  margin-top: $form-text-margin-top;
}

.form-wrap {
  .pr {
    padding-right: 2.3rem; //3rem
  }
  .pl {
    padding-left: 2.3rem;
  }

  .w700 {
    width: 100%;
    max-width: 700px;
  }
  .w570 {
    width: 100%;
    max-width: 570px;
  }
}

@media (max-width: 1200px) {
  .form-wrap {
    // .pr {
    //   padding-right: 1.5rem;
    // }
    // .pl {
    //   padding-left: 1.5rem;
    // }
  }
}

@media (max-width: 992px) {
  .form-wrap {
    &.w-lg{
      .pr {
        padding-right: 15px;
      }
      .pl {
        padding-left: 15px;
      }
    }
  }
}

@media (max-width: 768px) {
  .form-control {
    &.inline-block {
      &.w250 {
        width: 100%;
      }
    }
  }
  .form-wrap {
    .pr {
      padding-right: 15px;
    }
    .pl {
      padding-left: 15px;
    }
  }

  // .bt-tx-warp {
  //   .inline-block {
  //     display: block;
  //     margin-bottom: 0.65rem;

  //     &:last-child {
  //       margin-bottom: 0;
  //     }
  //   }
  // }
}

// Form grid
//
// Special replacement for our grid system's `.row` for tighter form layouts.

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$form-grid-gutter-width * 0.5;
  margin-left: -$form-grid-gutter-width * 0.5;

  > .col,
  > [class*='col-'] {
    padding-right: $form-grid-gutter-width * 0.5;
    padding-left: $form-grid-gutter-width * 0.5;
  }
}

// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.

.form-check {
  position: relative;
  display: block;
  padding-left: $form-check-input-gutter;
}

.form-check-input {
  position: absolute;
  margin-top: $form-check-input-margin-y;
  margin-left: -$form-check-input-gutter;

  &:disabled ~ .form-check-label {
    color: $text-muted;
  }
}

.form-check-label {
  margin-bottom: 0; // Override default `<label>` bottom margin
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0; // Override base .form-check
  margin-right: $form-check-inline-margin-x;

  // Undo .form-check-input defaults and add some `margin-right`.
  .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: $form-check-inline-input-margin-x;
    margin-left: 0;
  }
}

// Form validation
//
// Provide feedback to users when form field values are valid or invalid. Works
// primarily for client-side validation via scoped `:invalid` and `:valid`
// pseudo-classes but also includes `.is-invalid` and `.is-valid` classes for
// server side validation.

@each $state, $data in $form-validation-states {
  @include form-validation-state(
    $state,
    map-get($data, color),
    map-get($data, icon)
  );
}

.is-invalid {
  &.noBackground {
    padding-right: 0.75rem;
    background-image: none;
  }
}

// Inline forms
//
// Make forms appear inline(-block) by adding the `.form-inline` class. Inline
// forms begin stacked on extra small (mobile) devices and then go inline when
// viewports reach <768px.
//
// Requires wrapping inputs and labels with `.form-group` for proper display of
// default HTML form controls and our custom form controls (e.g., input groups).

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; // Prevent shorter elements from growing to same height as others (e.g., small buttons growing to normal sized button height)

  // Because we use flex, the initial sizing of checkboxes is collapsed and
  // doesn't occupy the full-width (which is what we want for xs grid tier),
  // so we force that here.
  .form-check {
    width: 100%;
  }

  // Kick in the inline
  @include media-breakpoint-up(sm) {
    label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    }

    // Inline-block all the things for "inline"
    .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0;
    }

    // Allow folks to *not* use `.form-group`
    .form-control {
      display: inline-block;
      width: auto; // Prevent labels from stacking above inputs in `.form-group`
      vertical-align: middle;
    }

    // Make static controls behave like regular ones
    .form-control-plaintext {
      display: inline-block;
    }

    .input-group,
    .custom-select {
      width: auto;
    }

    // Remove default margin on radios/checkboxes that were used for stacking, and
    // then undo the floating of radios and checkboxes to match.
    .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
    }
    .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: $form-check-input-margin-x;
      margin-left: 0;
    }

    .custom-control {
      align-items: center;
      justify-content: center;
    }
    .custom-control-label {
      margin-bottom: 0;
    }
  }
}
