$pe-7s-font-path: "~pe7-icon/dist/fonts";

@import "~pe7-icon/dist/scss/pe-icon-7-stroke.scss";

.pe-7s-rate_inventory{opacity:1 !important;}
.pe-7s-rate_inventory:before{content:"\E664"}
.vertical-nav-menu .metismenu-container .metismenu-link:hover i.pe-7s-rate_inventory{opacity:2 !important;}

.pe-7s-channel_interface{opacity:1 !important;}
.pe-7s-channel_interface:before{content:"\E61B"}
.vertical-nav-menu .metismenu-container .metismenu-link:hover i.pe-7s-channel_interface{opacity:2 !important;}

.pe-7s-reservation_list{opacity:1 !important;}
.pe-7s-reservation_list:before{content:"\E636"}
.vertical-nav-menu .metismenu-container .metismenu-link:hover i.pe-7s-reservation_list{opacity:2 !important;}

.pe-7s-room_available{opacity:1 !important;}
.pe-7s-room_available:before{content:"\E62E"}
.vertical-nav-menu .metismenu-container .metismenu-link:hover i.pe-7s-room_available{opacity:2 !important;}

.pe-7s-work_history{opacity:1 !important;}
.pe-7s-work_history:before{content:"\E665"}
.vertical-nav-menu .metismenu-container .metismenu-link:hover i.pe-7s-work_history{opacity:2 !important;}

.pe-7s-system_history{opacity:1 !important;}
.pe-7s-system_history:before{content:"\E634"}
.vertical-nav-menu .metismenu-container .metismenu-link:hover i.pe-7s-system_history{opacity:2 !important;}

.pe-7s-qna{opacity:1 !important;}
.pe-7s-qna:before{content:"\E64A"}
.vertical-nav-menu .metismenu-container .metismenu-link:hover i.pe-7s-qna{opacity:2 !important;}

.pe-7s-sales_analysis{opacity:1 !important;}
.pe-7s-sales_analysis:before{content:"\E64B"}
.vertical-nav-menu .metismenu-container .metismenu-link:hover i.pe-7s-sales_analysis{opacity:2 !important;}

.pe-7s-channel_management{opacity:1 !important;}
.pe-7s-channel_management:before{content:"\E69E"}
.vertical-nav-menu .metismenu-container .metismenu-link:hover i.pe-7s-channel_management{opacity:2 !important;}

.pe-7s-transaction_report{opacity:1 !important;}
.pe-7s-transaction_report:before{content:"\E65E"}
.vertical-nav-menu .metismenu-container .metismenu-link:hover i.pe-7s-transaction_report{opacity:2 !important;}

.pe-7s-hotel_management{opacity:1 !important;}
.pe-7s-hotel_management:before{content:"\E65D"}
.vertical-nav-menu .metismenu-container .metismenu-link:hover i.pe-7s-hotel_management{opacity:2 !important;}

.pe-7s-email_set{opacity:1 !important;}
.pe-7s-email_set:before{content:"\E639"}
.vertical-nav-menu .metismenu-container .metismenu-link:hover i.pe-7s-email_set{opacity:2 !important;}

.pe-7s-sms_set{opacity:1 !important;}
.pe-7s-sms_set:before{content:"\E627"}
.vertical-nav-menu .metismenu-container .metismenu-link:hover i.pe-7s-sms_set{opacity:2 !important;}

.pe-7s-system_configuration{opacity:1 !important;}
.pe-7s-system_configuration:before{content:"\E666"}
.vertical-nav-menu .metismenu-container .metismenu-link:hover i.pe-7s-system_configuration{opacity:2 !important;}

.pe-7s-api_service_control{opacity:1 !important;}
.pe-7s-api_service_control:before{content:"\E69D"}
.vertical-nav-menu .metismenu-container .metismenu-link:hover i.pe-7s-api_service_control{opacity:2 !important;}

.pe-7s-user_management{opacity:1 !important;}
.pe-7s-user_management:before{content:"\E693"}
.vertical-nav-menu .metismenu-container .metismenu-link:hover i.pe-7s-user_management{opacity:2 !important;}
