// List Group

@use "sass:math";

.todo-list-wrapper {
  .todo-indicator {
    position: absolute;
    width: 4px;
    height: 60%;
    @include border-radius($border-radius-lg);
    left: ($card-spacer-x * 0.5);
    top: 20%;
    opacity: 0.6;
    transition: opacity 0.2s;
  }

  .list-group-item {
    &:hover {
      .todo-indicator {
        opacity: 0.9;
      }
    }
  }

  .custom-control,
  input[checkbox] {
    margin-left: ($card-spacer-x * 0.5);
  }
}

.list-group-flush + .card-footer {
  border-top: 0;
}

.rm-list-borders {
  .list-group-item {
    border: 0;
    padding: math.div($card-spacer-y, 1.5) 0;
  }
}

.rm-list-borders-scroll {
  .list-group-item {
    border: 0;
    padding-right: ($card-spacer-y * 1.5);
  }
}

.board-list {
  .list-group-item {
    padding: 0.5rem 1.25rem;
    line-height: 2.6rem;

    &.fileList {
      background: #f7f7f7;
      line-height: 1.4rem;
      padding: 0.75rem 1.25rem;

      a {
        color: #4b4f52;
        font-size: 13px;
        font-weight: 100;
        margin-left: 10px;
      }
    }

    &.content {
      padding: 1rem 1.25rem;
      .widget-content-left {
        padding-left: 0.5rem;
        color: #4b4f52;
        font-weight: 100;
      }
    }
  }
}

@media (max-width: 768px) {
  .board-list {
    .list-group-item {
      line-height: 2rem;
      padding: 0.5rem 0.7rem;
      //padding: 0.75rem 1rem;

      &.fileList {
        padding: 0.5rem 0.7rem;
      }

      &.content {
        padding: 1rem;
        .widget-content-left {
          padding-left: 0;
        }
      }
    }
  }
}
