.picky {
  width: 100%;
  position: relative;
}
.picky * {
  box-sizing: border-box;
}
.picky__input {
  width: 100%;
  background-color: white;
  border: 0;
  border-width: 1px;
  border-color: #ced4da;
  border-style: solid;
  border-radius: .25rem;
  padding: 5px;
  text-align: left;
  position: relative;
  box-sizing: border-box;
  height: 38px;
}

.picky__input--disabled,
.picky__input--disabled + .picky__dropdown .option {
  color: rgb(95, 94, 94);
  background-color:#e9ecef;
}
.picky__input::after {
  position: absolute;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: '';
  right: 16px;
  top: 16px;
}
.picky__dropdown {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 500;
  background: white;
  border-left: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  top: 38px;
}
.picky__dropdown .option,
.picky__dropdown li {
  list-style: none;
  background-color: white;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
  padding: 0.5em;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  text-align: left;
}
.picky__dropdown .option:hover,
.picky__dropdown li:hover {
  background-color: #ecf0f1;
}
.picky__dropdown .option.selected,
.picky__dropdown li.selected {
  background-color: #ecf0f1;
}
.picky__dropdown .option:focus,
.picky__dropdown li:focus {
  outline: -webkit-focus-ring-color auto 5px !important;
}
.picky__dropdown .option input[type='checkbox'],
.picky__dropdown .option input[type='radio'],
.picky__dropdown li input[type='checkbox'],
.picky__dropdown li input[type='radio'] {
  margin-right: 3px;
}

/** 단일선택(라디오 버튼)일 때 옆의 체크박스 없애도록 CSS 변경 (2019-12-24 정훈의) START **/
.picky__dropdown .option input[type='checkbox'],
.picky__dropdown .option input[type='radio'],
.picky__dropdown li input[type='checkbox'],
.picky__dropdown li input[type='radio']{
  opacity:0;
  z-index:-1;
  position:absolute;
}

.picky-radio .picky__dropdown .option{
  position:relative;
  padding-left:0.5em;
}

.picky-checkbox .picky__dropdown .option{
  position:relative;
  padding-left:1.9em;
}

.picky-checkbox .picky__dropdown .option.selected::before{
  background-color:#545cd8;
  border-color:#1890ff;

}

.picky-checkbox .picky__dropdown .option.selected::after{
  position:absolute;
  top:9px;
  left:6px;
  width:1rem;
  height:1rem;
  content:'';
  background:no-repeat 50% / 50% 50%;
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.picky-checkbox .picky__dropdown .option::before{
  position:absolute;
  top:9px;
  left:6px;
  width:1rem;
  height:1rem;
  content:'';
  border:1px solid #adb5bd;
  border-radius:0.25rem;
  background:#fff;
}
/** 단일선택(라디오 버튼)일 때 옆의 체크박스 없애도록 CSS 변경 (2019-12-24 정훈의) END **/

.picky__filter {
  display: flex;
  width: 100%;
  padding: 3px;
}
.picky__filter__input {
  width: 100%;
  padding: 3px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
  height: 30px;
}

.picky__placeholder {
  color: #b4b4b4;
}
