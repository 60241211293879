// Input groups

.input-group {
  .input-group-prepend {
    div:not([class]) .react-datepicker__input-container,
    & + div .react-datepicker__input-container {
      .form-control {
        @include border-left-radius(0);
        @include border-right-radius($input-border-radius !important);
      }
    }
  }

  & > div:not([class]) {
    position: relative;
    flex: 1 1 auto;
    width: 1%;

    & .react-datepicker__input-container {
      .form-control {
        @include border-right-radius(0);
      }

    }
  }
}

.input-group {
  .input-group-prepend {
    & + div {
      position: relative;
      flex: 1 1 auto;
      width: 1%;
    }
  }

  & > .react-datepicker-wrapper {
    position: relative;
    flex: 1 1 auto;
    width: 1%;

    & > .react-datepicker__input-container > .form-control {
      @include border-right-radius(0);
    }
  }
}

.date-navi-arrow-wrap{
  display:flex;
  align-items: center;
  .date-pickr-navi{
    margin-left:0.5rem;
    display:flex;
    align-items: center;
    .date-pickr-prev, .date-pickr-next{
      // display:inline-block;
      padding:0 8px;
      height:39px;
      line-height:36px;
      cursor:pointer;
      border:1px solid #ced4da;
      border-radius: 0.25rem;
      background:#eff9ff;
      float:left;
      // &:hover{
      //   background:#eff9ff;
      // }
    }
    .date-pickr-text{
      border:1px solid #ced4da;
      border-radius: 0.25rem;
      padding:0 8px;
      height:39px;
      font-size:0.74rem;
      // display:inline-block;
      margin:0 0.5rem;
      line-height:36px;
      float:left;
      white-space:nowrap;
    }
  }
  
}

// Forms Legend

legend {
  font-size: $font-size-base;
  font-weight: bold;
}

// Headings

.form-heading {
  font-size: $font-size-lg;
  margin: 0;
  color: $primary;

  p {
    color: $gray-600;
    padding: 0.3rem 0 0;
    font-size: $font-size-base;
  }

  &.header{
    > div{
      //width:25%;
      text-align:center;
      position:relative;
      font-weight:500;
      font-size:.94rem;
      
      &::after{
        content:'|';
        color:rgba(32, 39, 140, 0.125);
        position:absolute;
        right:0;
      }
    }

    > div:last-child{
      &::after{
        display:none;
      }
    }

    > div:first-child{
      text-align:left;
      padding-left:2.5rem;
      //width:18%;
    }
  }
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
}