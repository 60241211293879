.Success{
    background-color:#F9FEF9;
    color:#21CA6B;
}
.Success .modal-content{}
.Success .modal-content .modal-header{
    background-color:#F9FEF9;
    border-bottom:0px;
}
.Success .modal-content .modal-body{
    background-color:#F9FEF9;
}
.Success .modal-content .modal-footer{
    background-color:#F9FEF9;
    border-top:0px;
}
.Success .modal-content .modal-footer button{
    background-color:#21CA6B;
}

.Warning{
    background-color:#F9F8F3;
    color:#D0A601;
}
.Warning .modal-content{}
.Warning .modal-content .modal-header{
    background-color:#F9F8F3;
    border-bottom:0px;
}
.Warning .modal-content .modal-body{
    background-color:#F9F8F3;
}
.Warning .modal-content .modal-footer{
    background-color:#F9F8F3;
    border-top:0px;
}
.Warning .modal-content .modal-footer button{
    background-color:#F2C200;
    color:#FFFFFF;
}

.Warning .modal-content .modal-footer .CustomerButton{
    background-color:#D1D5D8;
}

.Warning .modal-content .modal-footer .CustomerButtonOutline{
    border-color:#D1D5D8;
}

.Warning .modal-content .modalMessage{
    color: #495057;
    font-weight: 100;
    font-size: 0.84rem;
}

.Error{
    background-color:#F8F5F5;
    color:#E94D3C;
}
.Error .modal-content{}
.Error .modal-content .modal-header{
    background-color:#F8F5F5;
    border-bottom:0px;
}
.Error .modal-content .modal-body{
    background-color:#F8F5F5;
}
.Error .modal-content .modal-footer{
    background-color:#F8F5F5;
    border-top:0px;
}
.Error .modal-content .modal-footer button{
    background-color:#E94D3C;
    color:#FFFFFF;
}

.Error .modal-content .modal-footer .CustomerButton{
    background-color:#D1D5D8;
}

.Error .modal-content .modal-footer .CustomerButtonOutline{
    border-color:#D1D5D8;
}