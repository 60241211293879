// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.state {
  display: inline-block;
  padding: 0.125rem 0.7rem;
  font-size:0.73rem;
  font-weight: $font-weight-normal;
  line-height: 1.85;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: transparent;
  border: $btn-border-width solid transparent;
  @include border-radius($badge-border-radius);
  @include transition($badge-transition);

  @at-root a#{&} {
    @include hover-focus {
      text-decoration: none;
    }
  }

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }
}

.state-danger{
  color: #fff;
  background-color: #d92550;
  border-color: #d92550;
}

.state-warning {
  color: #212529;
  background-color: #f7b924;
  border-color: #f7b924;
}

.state-info {
  color: #fff;
  background-color: #30b1ff;
  border-color: #30b1ff;
}

.state-success {
  color: #fff;
  background-color: #3ac47d;
  border-color: #3ac47d;
}

.state-light {
  color: #212529;
  background-color: #eeeeee;
  border-color:gainsboro;
}

.state-outline-danger{
  color: #d92550;
  border-color: #d92550;
}

.room-close{
  color: #d92550;
  background-color: initial;
  border-color: initial;
  border-width: 0px;
  font-size:0.85rem;
  font-weight: 500;
}

.btn-close{
  color:#d92550 !important;
  background-color:inherit !important;
  border-color: #d92550 !important;
  font-size: 0.8rem !important;
}

.btn-close:hover{
  color:#fff !important;
  background-color:#b81f44 !important;
  border-color:#ad1e40 !important;
}

.btn-auto-close{
  color:#d92550 !important;
  background-color:inherit !important;
  border-color: #d92550 !important;
  font-size: 0.8rem !important;
  border-radius:1.5rem;
}

.btn-auto-close:hover{
  color:#fff !important;
  background-color:#b81f44 !important;
  border-color:#ad1e40 !important;
  border-radius:1.5rem;
}

.btn-open{
  color:#51a22f !important;
  background-color:inherit !important;
  border-color:#51a22f !important;
  font-size: 0.8rem !important;
}

.btn-open:hover{
  color:#fff !important;
  background-color: #23a20b !important;
  border-color: #2ba20d !important;
}
