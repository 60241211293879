.index-board{
    .timeline-board-title{
      font-size:0.88rem !important;
      overflow:hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      
      a{
        color:#495057
      }
      // button{
      //   font-size:0.8rem !important;
      //   padding:0.175rem 1.15rem !important;
      // }
    }
    .timeline-board-date{
      font-size:0.88rem !important;
      text-align:right;
    }
  
    .timeline-info{
      font-size:0.88rem !important;
    }
    
    .widget-content-left{
      width:calc(100% - 140px);         
    }

    .widget-content-right{
      width:140px;
    }
   
  }
  
  
  .txt-overinfo{
    display:inline-block;
    position:absolute;
    right:20px;
    top:-35px;
  
    &:hover {
      span{
        opacity:1;
      }
    }
  
   span {
     background:#fff;
     border:1px solid #545cd8;
     padding:8px;
     font-size:0.73rem;
     white-space:nowrap;
     -webkit-transition: opacity .2s ease-in;
      -moz-transition: opacity .2s ease-in;
      -o-transition: opacity .2s ease-in;
      -ms-transition: opacity .2s ease-in;
     transition:opacity .2s ease-in;
     opacity:1;
     text-align:left;
     font-weight:normal;
     display:block;
   }
  
  }
  
  .txt-info-box{
    display:inline-block;
    //position:relative;
    &:hover {
      span{
        opacity:1;
        animation: fade-in2 .2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
      }
    }

    &.fixed{
      span{
        position:fixed;
        z-index:9998;
        u.arrow{
          margin-bottom:-1px;
        }
      }
      
    }
  
    em{
      cursor: pointer;
     font-style:normal;
  
     &::after {
      content:url('~assets/utils/images/icon/ico-info.png');
      display:inline-block;
      margin-left:3px;
      vertical-align:middle;
      }
      
    }

    &.wh {
      em {        
        background: #fff;
        display: inline-block;
        text-align: center;
        width: 19px;
        height: 19px;
        border-radius: 19px;
        border: 1px solid #545cd8;
        color: #545cd8;
        font-size: 0.7rem;
        line-height: 18px;
        padding-left: 1px;
        margin-right: 5px;
        cursor: pointer;
        &::after {
          display: none;
        }
      }
    }
   
    span {
      z-index:100;
      background:#fff;
      border:1px solid rgba(32,39,140,.125);
      padding:8px;
      font-size:0.75rem;
      white-space:nowrap;
      -webkit-transition: opacity .2s ease-in;
        -moz-transition: opacity .2s ease-in;
        -o-transition: opacity .2s ease-in;
        -ms-transition: opacity .2s ease-in;
      transition:opacity .2s ease-in;
      opacity:0;
      text-align:left;
      font-weight:normal;
      display:block;
      position:absolute;
      line-height:1.4;
      top:-25px;
      right:5px;
      background-clip: padding-box;
      // border: 1px solid #545cd8;
      border-radius:.3rem;
      box-shadow:0 .25rem .5rem rgba($black, .2);
      u.arrow{
        width:1rem;
        height:.5rem;
        margin:0.3rem;
        position:absolute;
        display:block;
        left:calc(50% - 0.5rem);
        bottom:-13px;
        &::before, &::after{
          position: absolute;
          display: block;
          content: "";
          border-color: transparent;
          border-style: solid;
        }

        &::after{
          bottom: 0px;
          border-width: .5rem .5rem 0;
          border-top-color: #fff !important;
        }
        &::before{
          bottom: 0;
          border-width: .5rem .5rem 0;
          border-top-color: rgba(0,0,0,1) !important;;
        }
      }
    }
  
  }

  @keyframes fade-in2 {
    0% {
      margin-top: -50px;
      visibility: hidden;
      opacity: 0;
    }
    100% {
      margin-top: 0px;
      visibility: visible;
      opacity: 1;
    }
  }
  
  .nowTime{
    display:flex;
    align-items:center;
    font-size:0.8rem;
    font-weight:normal;
  }

  .recent_record{
    .vertical-timeline-element-content{
      .timeline-info{
        font-size:0.88rem;
      }
    }
  }


  .no-tab-tit{
    .rc-tabs-nav-wrap{
      display:none;
    }
  }


  .main-chart{
    .widget-content{
      .widget-numbers{
        font-size:2.5rem;
      }
    }
  }


  .chart-bar{
    height:.3rem;
    border-radius:0.25rem;
    overflow:hidden;
    background-color:#e9ecef;
  }