// Content Boxes

// Variables

@use "sass:math";

$widget-spacer: $spacer;

.board-list {
  .s-title {
    color: #595b82;
  }
  .widget-content {
    .widget-content-left {
      float: left;
      line-height: 1.6rem;
      &.order {
        width: 8%;
        min-width: 103px;
        text-align: center;
        padding-right: 1rem;

        .widget-heading {
          position: relative;
          color: #4b4f52;
          font-weight: 500;
          padding-right: 1.15rem;
          &:after {
            content: '';
            position: absolute;
            top: 6px;
            right: 0;
            width: 1px;
            height: 16px;
            background: #e9ecef;
          }
        }

        .arrow {
          font-size: 1.15rem;
          color: #bebebe;
        }

        .vt {
          vertical-align: 3px;
        }
      }

      &.state {
        width: 4.8%;
        text-align: center;
        padding: 0;
      }

      &.tit {
        width: 92%;
      }

      &.tit2 {
        width: calc(79.2% - 100px);
      }

      &.w100 {
        width: 100%;
      }

      .widget-heading {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-wrap: normal;
        font-weight: normal;
        max-height: 41px;
        opacity: 1;
        color: #4b4f52;

        a {
          color: #4b4f52;
          font-weight: 400;
        }
        a:hover {
          text-decoration: none;
        }
      }
    }

    .widget-content-right {
      &.date {
        width: 8%;
        text-align: center;
        color: #5f6265;
      }
    }

    .imp {
      .widget-content-left {
        &.tit {
          width: 87.2%;
        }
      }
    }

    .tri-expanded {
      background: none;
      border: 0;
      width: 100%;
      height: 40px;
      color: #4b4f52;
      font-weight: 400;
    }

    .faAngle{
      background: none;
      border: 0;
      width: 100%;
      height: 40px;
      color: #4b4f52;
      font-weight: 400;
      .Angle{
        color:#545cd8;
      }
    }
  }
}

@media (max-width: 1400px) {
  .board-list {
    .widget-content {
      .widget-content-left {
        &.state {
          width: 6.5%;
          text-align: center;
        }
      }
      .widget-content-right {
        &.date {
          width: 10%;
        }
      }

      .imp {
        .widget-content-left {
          &.tit {
            width: 83.5%;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .board-list {
    .widget-content {
      .widget-content-left {
        line-height: 1.4rem;
        &.state {
          width: 8%;
          text-align: center;
        }
      }
      .widget-content-right {
        &.date {
          width: 13%;
        }
      }

      .imp {
        .widget-content-left {
          &.tit {
            width: 79%;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .board-list {
    .widget-content {
      .widget-content-left {
        &.order {
          width: 4%;
          min-width: 30px;
          padding-right: 0;

          .widget-heading {
            padding-right: 0;
            &:after {
              display: none;
            }
          }

          .vt {
            display: none;
          }
        }

        &.state {
          width: 11%;
          text-align: center;
        }
        &.tit2 {
          width: 82%;
        }
      }
      .widget-content-right {
        &.date {
          display: none;
        }
      }
      .imp {
        .widget-content-left {
          &.tit {
            width: 89%;
          }
        }
      }
    }
  }
}

@media (max-width: 470px) {
  .board-list {
    .widget-content {
      .widget-content-left {
        &.state {
          width: 16.4%;
          text-align: center;

          .btn {
            padding: 0.35rem 0.6rem;
          }
        }
        &.tit2 {
          width: 72%;
        }
      }
      .imp {
        .widget-content-left {
          &.tit {
            width: 81.5%;
          }
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .board-list {
    .widget-content {
      .widget-content-left {
        &.state {
          width: 19%;
        }
      }
    }
  }
}

.widget-content {
  padding: $widget-spacer;
  flex-direction: row;
  align-items: center;

  .widget-content-wrapper {
    display: flex;
    flex: 1;
    position: relative;
    align-items: center;
  }

  .widget-content-left {
    .widget-heading {
      opacity: 0.8;
      font-weight: bold;
    }

    .widget-subheading {
      opacity: 0.5;
    }
  }

  .widget-content-right {
    margin-left: auto;
  }

  .widget-numbers {
    font-weight: bold;
    font-size: 1.8rem;
    display: block;
  }

  .widget-content-outer {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .widget-progress-wrapper {
    margin-top: $widget-spacer;

    .progress-sub-label {
      margin-top: math.div($widget-spacer, 3);
      opacity: 0.5;
      display: flex;
      align-content: center;
      align-items: center;

      .sub-label-left {
      }

      .sub-label-right {
        margin-left: auto;
      }
    }
  }

  .widget-content-right {
    &.widget-content-actions {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.2s;
    }
  }

  &:hover {
    .widget-content-right {
      &.widget-content-actions {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
