.table-cell .MuiFormControl-root .MuiInputBase-root{
    padding: 0;
}

.MuiInputBase-root{
    font-family: inherit !important;
}

.MuiFormControl-root{font-size:0.88rem;}

.table-cell .fromToDate {
    width: 47.5%;
}

.ag-react-container .fromToDate{
    display:flex;
    align-items: center;
    width:100%;
    padding:6px 0;
    height:100%;
    justify-content: center;
}

.ag-react-container .fromToDate .MuiInputBase-input, 
.ag-react-container .fromToDate .MuiInputBase-root{
    /* height:100% !important; */
}

.ag-react-container .fromToDate .MuiIconButton-root{
    padding:0;
}


.ag-react-container .fromToDate .MuiFormControl-root{
    /* height:100%; */
    flex-direction:wrap;
}

.fromToDate .MuiFormControl-root{
    top:0;
}

.fromToDate {
    display: inline-flex;
}

.fromToDate .MuiOutlinedInput-root{
    display:block;
    padding-left:40px !important;
}


.fromToDate .MuiOutlinedInput-adornedEnd{
    padding-right:0;
}


.fromToDate .MuiInputAdornment-positionEnd{
    display:inline-block !important;
    position:absolute;
    left:0;
    top:0;
    margin-left:0;
    width:40px;
    background:#eff9ff;
    height:100%;
    min-height:100%;
    border-right:1px solid #ced4da;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.fromToDate .MuiInputAdornment-positionEnd .MuiIconButton-root{
    color:#495057;
    /* height:100%; */
    width:100%;
    height:100%;
    display:flex;
    align-items: center;
}

.fromToDate .MuiInputAdornment-positionEnd .MuiIconButton-root .svg-inline--fa{
    height:auto;
    width:14px !important;
}

.fromToDate .MuiInputBase-input{
    /* width:calc(100% - 40px); */
    /* height:calc(1.85em + 0.75rem + 2px) !important; */
    height:39px !important;
    line-height:1.85;
    padding:0 0.75rem !important;
    box-sizing: border-box;
    background:#fff;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    font-size:0.84rem !important;
}


.fromToDate .MuiOutlinedInput-notchedOutline{
    border-color:#ced4da !important;
    border-width:1px !important;
    border-radius: 0.25rem;
    /* background:#fff; */
}

