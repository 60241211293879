// base
.top4 {
  top: -4px;
  position: relative;
}

.w90 {
  min-width: 90px;
}

.w120 {
  min-width: 120px;
}

.w165 {
  min-width: 165px;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-2rem {
  margin-bottom: 2rem !important;
}

.mb-1_3rem {
  margin-bottom: 1.3rem !important;
}

.mt-2rem {
  margin-top: 2rem !important;
}

.mem-txt.h6 {
  line-height: 1.5;
  font-size: 0.8rem;
}

.font-size-085rem {
  font-size: 0.85rem;
}

.font-size-09rem {
  font-size: 0.9rem;
}

.font-size-093rem {
  font-size: 0.93rem;
}

.font-size-095rem {
  font-size: 0.95rem;
}

.font-size-rem {
  font-size: 1rem;
}

.font-size-075rem {
  font-size: 0.75rem;
}

.border-grade {
  border-color: #fff;
}

// Icon
.exit-ico {
  text-indent: -9998px;
  width: 43px;
  height: 38px;
  background: url('~assets/utils/images/icon/ico-exit.png') no-repeat;
  margin: 0 auto;
}

.lock-ico {
  text-indent: -9998px;
  width: 55px;
  height: 55px;
  background: url('~assets/utils/images/icon/ico-lock.png') no-repeat;
  margin: 0 auto;
}

.uncertifi-ico {
  text-indent: -9998px;
  width: 55px;
  height: 55px;
  background: url('~assets/utils/images/icon/ico-uncertifi.png') no-repeat;
  margin: 0 auto;
}

.warning-ico {
  text-indent: -9998px;
  width: 55px;
  height: 55px;
  background: url('~assets/utils/images/icon/ico-warning.png') no-repeat;
  margin: 0 auto;
}

// content
.app-login-box {
  .card-body {
    padding: 0.25rem 1.15rem;
  }
}

.card-body {
  .list {
    margin-bottom: 0.7rem !important;
    //padding-left: 1rem;

    .title {
      display: inline-block;
      width: 100px;
      font-weight: 500;
      text-align: right;
      padding-right:20px;
      color:#595b83;
    }
  }
  .list:last-child {
    margin-bottom: 0 !important;
  }
}

.txt-info {
  display: inline-block;
  position: relative;

  &:hover {
    span {
      opacity: 1;
      animation: fade-in2 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }
  }

  em {
    cursor: pointer;
    font-style: normal;

    &::after {
      content: url('~assets/utils/images/icon/ico-info.png');
      display: inline-block;
      margin-left: 3px;
      vertical-align: middle;
    }
  }

  span {
    position: absolute;
    left: calc(100% + 1px);
    bottom: 22px;
    background: #fff;
    border: 1px solid #545cd8;
    padding: 8px;
    font-size: 0.8rem;
    white-space: nowrap;
    -webkit-transition: opacity 0.2s ease-in;
    -moz-transition: opacity 0.2s ease-in;
    -o-transition: opacity 0.2s ease-in;
    -ms-transition: opacity 0.2s ease-in;
    transition: opacity 0.2s ease-in;
    opacity: 0;
  }
}

.font-left-icon {
  i {
    font-size: 1.4rem;
    margin-right: 6px;
    vertical-align: middle;
    position: relative;
    top: -2px;
  }
}

.tab-content {
  h4 {
    margin-bottom: 1rem !important;
    font-size: 1.6rem;
  }

  .btn-icon-vertical {
    min-width: 140px;
  }
}

.tit-txt {
  line-height: 0.1 !important;
  font-size: 0.9rem;
}

.modal-title.no-lineH {
  line-height: 1 !important;
  h4 {
    margin-bottom: 0.5rem !important;
  }
}

.btn-maxW {
  .btn.btn-wide {
    padding: 0.5rem 2rem;
    min-width: 185px;
  }
}

.info-block {
  // display:inline-block;
  position: relative;
}

// .info-block:hover{
//   .btn-info-hover{
//     opacity:1;
//   }
// }

.btn-info-hover {
  position: absolute;
  bottom: 54px;
  right: -15px;
  background: #4c4c4c;
  font-size: 0.75rem;
  font-weight: 300;
  padding: 0.6rem;
  border-radius: 5px;
  color: #fff;
  text-align: left;
  white-space: nowrap;
  // -webkit-transition: opacity .2s ease-in;
  // -moz-transition: opacity .2s ease-in;
  // -o-transition: opacity .2s ease-in;
  // -ms-transition: opacity .2s ease-in;
  // transition:opacity .2s ease-in;
  // opacity:0;
  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #4c4c4c;
    position: absolute;
    bottom: -7px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

.menu2-header-title {
  font-weight: 500;
  line-height: 2.2rem;
  font-size: 1.75rem;
  margin: 0;
}

.menu2-header-subtitle {
  margin: 12px 0 0;
  opacity: 0.8;
  font-size: 1.1rem;
}

.menu3-header-title {
  line-height: 2.6rem;
  font-weight: 500;
  font-size: 1.55rem;
  margin: 0;
}

.warning {
  .menu3-header-title {
    margin-top: 1.8rem;
  }
}

.menu3-header-subtitle {
  margin: 12px 0 0;
  font-size: 1rem;
  line-height: 1.7;
  color: #92969a;
}

.modal-footer {
  .href-wide {
    font-size: 0.9rem;
  }
}

.href-wide {
  font-size: 0.95rem;
  margin-right: 1rem;
}

.result-txt {
  line-height: 1.5;
  font-size: 0.88rem;
}

.alert-info {
  font-size: 0.84rem;
}

.topFix {
  .alert-info {
    font-size: 0.76rem;
  }
}

.topFix {
  position: fixed !important;
  max-width: 500px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.bottomFix {
  position: fixed !important;
  //max-width: 500px;
  left: 310px;
  //right: 0;
  bottom: 0;
  //padding-left: 280px;
  margin: 0 auto;
}

.top-alert {
  position: fixed;
  width: 500px;
  top: 70px;
  left: 50%;
  margin-left: -220px;
  z-index: 999;
  .alert {
    font-size: 0.76rem;
  }
}

.addLine {
  border-top: 1px solid #dee2e6;
}

.mail-footer {
  padding: 1.5rem 1.5rem;
  color: #777;
  font-size: 0.8rem;
  background: #f8f9fa;
  p {
    line-height: 1.5;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;

    margin-left: 0 !important;
  }
  p:last-child {
    margin-bottom: 0;
  }

  dl {
    line-height: 1.7;
    margin: 0;
    dt {
      font-weight: bold;
    }
    dd {
      margin: 0;
      position: relative;
      padding-left: 10px;
      &::after {
        position: absolute;
        left: 0;
        top: -2px;
        content: '-';
      }
    }
    dd:last-child {
      margin-bottom: 0;
    }
  }
}

ul.li-50 {
  padding: 0;
  margin: 0;
  li {
    list-style-type: none;
  }
}

// media
@media (max-width: 1266px) {
  .bottomFix {
    left: 110px;
  }
}

@media (max-width: 991px) {
  .bottomFix {
    left: 30px;
  }
}

@media (max-width: 768px) {
  .bottomFix {
    left: 15px;
  }
}

@media (max-width: 576px) {
  .btn-maxW {
    .btn.btn-wide {
      min-width: inherit;
      width: 100%;
    }
  }
  // .info-block{
  //   display:block !important;
  // }

  ul.li-50 {
    li {
      width: 50%;
      padding: 0 3px;
      box-sizing: border-box;
    }
  }

  .btn-info-hover {
    left: 0 !important;
    right: 0 !important;
    white-space: normal;
    display: none !important;
  }

  .menu2-header-title {
    font-size: 1.3rem;
  }

  .menu2-header-subtitle {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  .menu3-header-title {
    font-size: 1.2rem;
    line-height: 1.7;
  }

  .menu3-header-subtitle {
    font-size: 0.9rem;
  }

  .modal-footer .href-wide {
    font-size: 0.85rem;
    margin-right: 0.65rem;
  }

  .top-alert {
    width: 90%; left: 5%; margin-left: 0;
  }
}
