
#zz12_0100_2__grid .ag-cell-data-changed {
    background-color: transparent !important;
}


#zz12_0100_2__grid .ag-cell-data-changed-animation {
    background-color: transparent;
    transition: background-color 0s;
}
