
// Header Buttons

.header-btn-lg {
  padding: 0 0 0 ($layout-spacer-x);
  margin-left: ($layout-spacer-x);
  display: flex;
  align-items: center;
  position: relative;

  &::before {
    position: absolute;
    left: -1px;
    top: 50%;
    background: $border-color;
    width: 1px;
    height: 30px;
    margin-top: -15px;
    content: '';
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background: $gray-600;
  }

  .icon-wrapper-alt{
    width:44px;
    height:44px;
    margin:0;
    text-align:center;

    .icon-wrapper-bg{
      opacity: .1;
      -webkit-transition: opacity .2s;
      transition : opacity .2s;
    }
  }

}