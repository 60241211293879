@import 'variables';

.rbc-event {
  cursor: pointer;
  padding: $event-padding;
  background-color: $event-bg;
  border-radius: $event-border-radius;
  color: $event-color;

  &.rbc-selected {
    background-color: darken($event-bg, 10%);
  }

  &.category-cell{
    background:none;
    padding:0;
    // position:relative;
    // top:15px;
    .rbc-event-content{
      overflow:inherit;
    }

    .peeSet-area{
      position:relative;
      .icoArea{
        position:absolute;
        top:-19px;
        left:6px;
      }
      .checkArea{
        position:absolute;
        top:-21px;
        right:20px;
      }
      .btnArea{
        display:flex;
        align-items:center !important;
        justify-content:center !important;
        position:relative;
        top:20px;
        .btn{
          //max-width:100%;
          width:70%;
        }
      }
    }
  }
}

.rbc-event-label {
  @extend .rbc-ellipsis;
  font-size: 80%;
}

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51,51,51,.5);
}

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}


.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
