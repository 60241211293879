//
// Basic Bootstrap table
//

.table {
  width: 100%;
  margin-bottom: $spacer;
  color: $table-color;
  background-color: $table-bg; // Reset for nesting within parents with `background-color`.

  th,
  td {
    padding: $table-cell-padding;
    vertical-align: top;
    //border-top: $table-border-width solid $table-border-color;
    border-top: 1px solid #dee2e6;
    font-size: 0.85rem;
  }

  thead th {
    vertical-align: bottom;
    //border-bottom: (2 * $table-border-width) solid $table-border-color;
    border-bottom: 2px solid #dee2e6;
  }

  tbody + tbody {
    border-top: (2 * $table-border-width) solid $table-border-color;
  }
}

//
// Condensed table w/ half padding
//

.table-sm {
  th,
  td {
    padding: $table-cell-padding-sm;
  }
}

// Border versions
//
// Add or remove borders all around the table and between all the columns.
// .tableWrap{
//   border-top:3px solid #65626f;
// }
.table-bordered {
  //border: $table-border-width solid $table-border-color;
  border: 1px solid #dee2e6;

  th,
  td {
    //border: $table-border-width solid $table-border-color;
    border: 1px solid #dee2e6;
  }

  thead {
    th,
    td {
      border-bottom-width: 2 * $table-border-width;
    }
  }

  &.gridStyle{
    // overflow:hidden;
    position:relative;
    // border-top:3px solid #65626f !important;
    // z-index:10;
    background:transparent;
    // border-top:3px solid #65626f !important;
    // border-top:3px solid #65626f !important;
    tr{
      position:relative;
    }
    th,
    td {
      text-align:center;
      white-space: nowrap;
      position:relative;
    }

    .tbody_th{
      vertical-align:top;
      //background-color:#fff;
    }

    .td-row-group-indent-1{
      padding-left:24px;
    }

    .td-row-group-indent-2{
      padding-left:48px;
    }

    thead{
      background-color:#f8f9fa;
      border-top:3px solid #65626f;
     // border-top:3px solid #65626f;
      th{
        border-bottom-width:1px;
        border-bottom-color:#b8b5c5;
         
       &.addbd_L{
          background-color:#f8f9fa;
          // &::after{
          //   content:'';
          //   position:absolute;
          //   right:-1px;
          //   width:1px;
          //   height:100%;
          //   background:#dee2e6;
          //   z-index:10;
          //   top:0;
          // }
          &::after{
            content:'';
            position:absolute;
            bottom:-1px;
            width:100%;
            height:1px;
            background:#b8b5c5;
            z-index:10;
            left:0;
          }
      }
       
      }
      &.fixThead{
        position:fixed;
        top:61px;
        z-index:100;
      }
    }

    tbody{
      //border:3px solid red;
      th{
        background:#fff !important;
        font-weight:normal;
      }
      td{
        padding: 0.45rem;
        &.addbd_L{
          &::after{
            content:'';
            position:absolute;
            bottom:-1px;
            width:100%;
            height:1px;
            background:#dee2e6;
            z-index:10;
            left:0;
          }
        }

        .form-control{
          &.input-num{
            height:30px;
            text-align:right;
            padding:0.275rem 0.12rem;
            font-size:0.78rem;
            min-width:86px;
          }
        }
        &.input-area{
          padding:0.25rem 0.2rem;
        }
      }
      .line{ 
        font-style:normal;
        color:#e9ecef;
        display:inline-block;
        margin-left:9px;
        margin-right:9px;
      }
      .product td{ //호텔객실명 하이라이트
        background-color:#f2f3ff;
      }

      .td-indent-0-bg{
        background-color:#e7e9fa;       
      }

      .td-indent-1-bg{
        background-color:#f2f3ff;
        &.parent{
          &::before{
            content:'';
            width:24px;
            height:100%;
            background:#e7e9fa;
            position:absolute;
            left:0;
            top:0;
          }
        }       
      }

      .td-indent-2-bg{
        &.parent{
          &::before{
            content:'';
            width:24px;
            height:100%;
            background:#e7e9fa;   
            position:absolute;
            left:0;
            top:0;
          }
          &::after{
            content:'';
            width:24px;
            height:100%;
            background:#f2f3ff;
            position:absolute;
            left:24px;
            top:0;
          }
        } 
      }


      .division{ //구분선
        td{
        border-top-width:3px;
        }
      }
      
      .bgHighlight{
        background:rgba(224,243,255,1) !important; 
      }

      .alert-warning{      
        background-color:#fdf1d3 !important;
        &.bgHighlight{
          opacity:.55;
        }
      }

      .alert-danger{
        background-color:#f7d3dc !important;
        &.bgHighlight{
          opacity:.55;
        }
      }

      .disable-bg{
        background-color:#9fa5aa !important;
        color:#50585e;
        &.bgHighlight{
          opacity:.55;
        }      
      }

    }

    .ico-danger{
      color:#d92550;
      width:18px;
      height:18px;
      line-height:15px;
      text-align:center;
      border:1px solid #d92550;
      border-radius: 50%;
      display:inline-block;
      font-size:0.84rem;
      margin-right:5px;
    }


    .expandable{
      position:relative;
      display:block;
      padding-left:25px;
      .pe-angle{
        font-weight:bold;
        font-size:1.7rem;
        // top:0.5rem;
        // position:relative;
        position:absolute;
        left:0;
        top:-3px;
      }
      .opens{
        display:none;
      }
    }

    .expand-wrap[data-state='0']{
      .expandable{
        .closes{
          display:none;
        }
        .opens{
          display:inline-block;
        }
      }
    }


    .expand-wrap[data-state='1']{
      .expandable{
        .closes{
          display:inline-block;
        }
        .opens{
          display:none;
        }
      }
    }

    .setBtnPos{      
      position:absolute;
      bottom:calc(100% - 20px);
      // right:-13px;
      left:calc(100% - 35px);
      z-index:9;
      &.only{
        // top:-100%;

      }
      .btn{
        padding-top:0.25rem;
        padding-bottom:0.25rem;       
        &.setBt{
          background-color:#fff;
          display:block;
          margin-top:2px;
          &.copyBt{
            &:hover{
              background-color:#343a40;
            }
          }
          &.copyLabelBt{
            padding:0;
            height:32px;
            overflow:hidden;
            position:relative;
            // &:hover{
            //   background-color:#343a40;
            //   .tri{
            //     border-left:5px solid #fff !important;
            //   }
            // }
            .label{
              position:relative;
              //float:left;
              // display:inline-block;
              height:100%;
              line-height:29px;
              min-width:29px;
              text-align:center;
              padding:0 11px;

              &.showClass{
                .tri{
                  border-left:5px solid #fff !important;
                }
                &::before{
                  opacity: 1;
                }
              }
              .tri{
                width:0;
                height:0;
                border-left:5px solid #333;
                border-top:4px solid transparent;
                border-bottom:4px solid transparent;
                display:inline-block;
                margin:0 1px;
                position:relative;
                z-index:7;
               
              }
              &:last-child{
                &::after{
                  display:none;
                }
              }

              &::before{
                  content:'';
                  width:calc(100% + 2px);
                  background:#333;
                  position:absolute;
                  left:-2px;
                  height:34px;
                  opacity: 0;
                  z-index:6;
                  top:-6px;
                }
              
              &::after{
                content:'|';
                // display:inline-block;
                // margin:0 7px 0 5px;
                color:#ccc;
                position:absolute;
                right:0;
                height:30px;
                z-index:5
              }
            }
          }
          &.channelBt{
            &:hover{
              background-color:#83588a;
            }
          }
          &.salesBt{
            &:hover{
              background-color:#545cd8;
            }
          }
        }

      }
  }

  .btn{
    font-size:0.75rem;
  }
    
    // .disable-secondary{
    //   opacity:.65;
    //   background:#6c757d;
    //   .form-control{
    //     color:#495057 !important;
    //   }
    // } 
    // .disable-bg{
    //   position:relative;
    //   &::after{
    //     content:'';
    //     width:100%;
    //     height:100%;
    //     position:absolute;
    //     top:0;
    //     left:0;
    //     background-color:#6c757d !important;
    //     opacity:.65;
    //     z-index:20;
    //   }
    //   span{
    //     z-index:10;
    //     position:relative;
    //   }
    // }
    td{
      select{
        padding:0;
        height:auto;
        //background:transparent
      }
    }

    .part-2col{
      margin:0;
      padding:0;
      position:absolute;
      left:0;
      top:0;
      width:100%;
      height:100%;
      dd{
        width:50%;   
        height:50%;
        float:left;
        margin:0;
        padding:0;
        border-top:1px solid #dee2e6;
        border-left:1px solid #dee2e6;
        box-sizing: border-box;
        &:nth-child(1), &:nth-child(2){
          border-top:none;
        }
        &:nth-child(2n+1){
          border-left:none;
        }
        &.active{
          background:#d92550;
        }
      }
    }

    .autoSet{
      border:1px solid #ced4da;
      border-radius:0.25rem;
      height:28px;
      line-height:26px;
      display:flex;
      text-align:center;
      font-size:0.82rem;
      color:#8f8f8f;
      .txt{
         width:1%;
         flex:1 1 auto;
         &.light{
           color:#8f8f8f;
         }
         &.red{
          color:#d92550;
        }
      }
      .num{
        border-left:1px solid #ced4da;
        width:30px;
        .form-control{
          border:none;
          padding:0;
          height:100%;
          text-align:center;
          border-radius:0;
          border-top-right-radius:0.25rem;
          border-bottom-right-radius:0.25rem;
        }
      }
    }
    
  }

/*
    td:hover::before { 
      background-color: #e0f3ff !important;
      content: '';  
      height: 100%;
      left: -5000px;
      position: absolute;  
      top: 0;
      width: 10000px;   
      z-index: -2;        
    }
  
    //Column
    td:hover::after { 
        background-color: #e0f3ff !important;
        content: '';  
        height: 10000px;    
        left: 0;
        position: absolute;  
        top: -5000px;
        width: 100%;
        z-index: -1;        
    }
*/
  }

.table-borderless {
  th,
  td,
  thead th,
  tbody + tbody {
    border: 0;
  }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
  tbody tr:nth-of-type(#{$table-striped-order}) {
    background-color: $table-accent-bg;
  }
}

// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
  tbody tr {
    @include hover {
      color: $table-hover-color;
      background-color: $table-hover-bg;
    }
  }
}

// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

@each $color, $value in $theme-colors {
  @include table-row-variant(
    $color,
    theme-color-level($color, $table-bg-level),
    theme-color-level($color, $table-border-level)
  );
}

@include table-row-variant(active, $table-active-bg);

// Dark styles
//
// Same table markup, but inverted color scheme: dark background and light text.

// stylelint-disable-next-line no-duplicate-selectors
.table {
  .thead-dark {
    th {
      color: $table-dark-color;
      background-color: $table-dark-bg;
      border-color: $table-dark-border-color;
    }
  }

  .thead-light {
    th {
      color: $table-head-color;
      background-color: $table-head-bg;
      border-color: $table-border-color;
    }
  }
}

.table-dark {
  color: $table-dark-color;
  background-color: $table-dark-bg;

  th,
  td,
  thead th {
    border-color: $table-dark-border-color;
  }

  &.table-bordered {
    border: 0;
  }

  &.table-striped {
    tbody tr:nth-of-type(odd) {
      background-color: $table-dark-accent-bg;
    }
  }

  &.table-hover {
    tbody tr {
      @include hover {
        color: $table-dark-hover-color;
        background-color: $table-dark-hover-bg;
      }
    }
  }
}

// Responsive tables
//
// Generate series of `.table-responsive-*` classes for configuring the screen
// size of where your table will overflow.

.table-responsive {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        // Prevent double border on horizontal scroll due to use of `display: block;`
        > .table-bordered {
          border: 0;
        }
      }
    }
  }
}

//요금관리 셀 색상
.bgRateEmpty {
  background-color: #FFD9EC; 
}

.noSetFee {
  position: absolute;
  top: 0;
  right: 0;
  border-top : 8px solid #ff0000;
  border-left : 8px solid transparent;
}

.bgRateChange{
  //background-color:rgba(224,243,255,1); 
  //background-color:rgba(247, 235, 129, 1);
  //background-color:rgb(255, 210, 0);
  background-color:rgba(247, 226, 235, 1);
}