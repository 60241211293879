// Drawers

@use "sass:math";

.drawer-content-wrapper {
  background: $white;
  position: relative;
  padding: $layout-spacer-x;
  width:700px;
  // width:700px !important;
  // max-width:700px !important;

  .drawer-heading {
    font-size: 1.25rem;
    color: #fff;
    margin: 0;
    background: #f7ba24;
    // padding: ($layout-spacer-x / 2) $layout-spacer-x;
    padding: 20px;
    border-bottom: #f7b924 solid 1px;
    border-top: #f7b924 solid 1px;

    &.notice {
      background: #d92550;
      border-bottom: #d92550 solid 1px;
      border-top: #d92550 solid 1px;
    }

    &:first-child {
      border-top: 0;
    }
  }

  .drawer-section {
    padding: $layout-spacer-x;
  }

  & > .app-sidebar__heading:first-child {
    margin-top: 0;
  }
}

.drawer-nav-btn {
  @include border-radius(30px);
  background: #dea520;
  position: absolute;
  right: $layout-spacer-x * 0.5;
  top: $layout-spacer-x * 0.5;
  padding: math.div($layout-spacer-x, 3);
  //box-shadow: $box-shadow-sm;
  z-index: 200;

  &.notice {
    background: #d92550;
  }

  .hamburger {
    margin-top: 4px;
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: #fff !important;
  }
}
