.emailGridSelect {
  width: 100%;
  display: block !important;
}

.emailGridSelect .selectBox {
  display: inline-flex;
}

.emailGridSelect .inputDiv{
  display: inline-flex;
  // padding-right:8px;
}

.emailGridSelect .inputBox {
  width: 100%;
  height:36px;
  border:1px solid #ced4da;
  border-radius:0.25rem;
  color:#495057;
  padding:0 0.75rem;
  background-clip:padding-box;
}
