// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
  display: inline-block;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  color: $body-color;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: $btn-border-width solid transparent;
  @include button-size(
    $btn-padding-y,
    $btn-padding-x,
    $btn-font-size,
    $btn-line-height,
    $btn-border-radius
  );
  @include transition($btn-transition);

  @include hover {
    color: $body-color;
    text-decoration: none;

    .add-Radius-ico {background: #fff; color: #353ed1; font-weight: normal;}
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }

  &.minus {
    padding: 0;
    padding-bottom: 5px;
    width: 19px;
    height: 19px;
    line-height: 0;
  }

  &.lhsm {line-height: 1.2rem; height: 33px;}

  &.stateBt {min-width: 57px; line-height: 1rem; height: 28px; font-size: 0.83rem!important; margin-top: -2px;
    &.calendar{
      height:38px;
      line-height:1.2rem;
    }
  }
  &.viewBt {min-width: 72px; line-height: 1rem; height: 28px; font-size: 0.83rem!important;}
  &.viewBt{
    &::before{
      content:'+';
      position:relative;
      top:-1px;
      margin-right:5px;
    }
  }

  &.btn-outline-basic {border: 1px solid #747474; color: #444054;}
  &.btn-outline-basic:hover {background: #747474; color: #fff;}

  &.btn-orange{
    background:#f17421; border:1px solid #f17421;
    &:focus,
    &.focus {
      background:#f17421 !important; border:1px solid #f17421 !important;
      outline: 0;
      box-shadow: 0 0 0 0 rgba(226, 111, 35, 0.5);
    }
  }
  &.btn-orange:hover{background:#e26f23; border:1px solid #e26f23}
  &.btn-orange:active{background:#e26402 !important; border:1px solid #e26402 !important}

  &.w50 {
    min-width: 50px;
  }

  &.w57 {
    min-width: 57px;
  }

  &.w70 {
    min-width: 70px;
  }

  &.w77 {
    min-width: 77px;
  }

  &.w90 {
    min-width: 100px;
  }

  &.w100 {
    min-width: 100px;
  }

  &.w104 {
    min-width: 104px;
  }

  &.w110 {
    min-width: 110px;
  }

  &.w140 {
    min-width: 140px;
  }

  &.w180 {
    min-width: 180px;
  }

  &.w190 {
    min-width: 190px;
  }

  &.w200 {
    min-width: 200px;
  }

  &.w220 {
    min-width: 220px;
  }

  &vertical0 {vertical-align: 0;}

  &.formBt {
    background: #f6f7ff;
    font-size: 0.83rem!important;

  }

  &.btn-disable{
    background-color:#6c757d !important;
    border-color:#6c757d !important;
    color:#fff !important;
    opacity:0.65;
  }

  &.modify-ic {width: 16px; height: 16px; background: url('~assets/utils/images/icon/modify-ic.png') no-repeat; padding: 0;}
  &.save-ic {background: #353ed1 url('~assets/utils/images/icon/save-ic.png') center center no-repeat;}
  &.lock-ic {background: #eca909 url('~assets/utils/images/icon/lock-ic.png') center center no-repeat;}
  &.unlock-ic {background: #eca909 url('~assets/utils/images/icon/unlock-ic.png') center center no-repeat;}
  &.print-ic {background: #30b1ff url('~assets/utils/images/icon/print-ic.png') center center no-repeat;}
  &.excel-ic {background: #207245 url('~assets/utils/images/icon/excel-ic.png') center center no-repeat;}
  &.btn-excel {height: 34px; line-height: 19px;}
  .add-ic {padding-left: 20px; background: url('~assets/utils/images/icon/ico-add2.png') left center no-repeat;}
  .refresh-ic {padding-left: 21px; background: url('~assets/utils/images/icon/refresh-ic2.png') left 4px no-repeat;}
  .refresh-ic2 {padding-left: 21px; background: url('~assets/utils/images/icon/refresh-ic2.png') left 4px no-repeat;}
  .search-ic {padding-left: 21px; background: url('~assets/utils/images/icon/search-ic.png') left 3px no-repeat;}
  .setting-ic {padding-left: 22px; background: url('~assets/utils/images/icon/setting-ic.png') left 5px no-repeat;}
  .apply-ic {padding-left: 22px; background: url('~assets/utils/images/icon/apply-ic.png') left 5px no-repeat;}

  &.btn-light .add-ic {padding-left: 16px; background: url('~assets/utils/images/icon/ico-add3.png') left center no-repeat;}

  &.btn-outline-primary {
    .refresh-ic {background: url('~assets/utils/images/icon/refresh-ic.png') left 4px no-repeat;}
    &:hover {.refresh-ic {background: url('~assets/utils/images/icon/refresh-ic2.png') left 4px no-repeat;}}
    .refresh-ic2 {background: url('~assets/utils/images/icon/refresh-ic2.png') left 4px no-repeat;}
    &:hover {.refresh-ic2 {background: url('~assets/utils/images/icon/refresh-ic.png') left 4px no-repeat;}}

    &:hover {
      .ic-qs {
        color: #545cd8;
        background: #fff;
        font-weight: bold;
      }
    }
  }

}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

//
// Alternate buttons
//

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

.btn-excel {
  background-color: #207245;
  border-color: #207245;
}

.btn-deepPink {
  background-color: #e91e63;
  border-color: #e91e63;
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $link-color;
  text-decoration: $link-decoration;

  @include hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  // No need for an active state here
}

//
// Button Sizes
//

.btn-lg {
  @include button-size(
    $btn-padding-y-lg,
    $btn-padding-x-lg,
    $btn-font-size-lg,
    $btn-line-height-lg,
    $btn-border-radius-lg
  );
}

.btn-sm {
  @include button-size(
    $btn-padding-y-sm,
    $btn-padding-x-sm,
    $btn-font-size-sm,
    $btn-line-height-sm,
    $btn-border-radius-sm
  );
}

//
// Block button
//

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type='submit'],
input[type='reset'],
input[type='button'] {
  &.btn-block {
    width: 100%;
  }
}
